import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      dark: {
        primary: '#0076FF',
        deepblue: '#03013D',
        deepblueLight: '#161B51',
        red: '#FF5F33',
        green: '#14A86B',
        lightgreen: '#2CE6C2',
        white: '#FFFFFF',
        lightbluegray: '#8C8FB0',
        pink: '#AC60FC',
        gray: '#F4F5F7',
        deepbluelight: '#1A1D62',
      },
      light: {
        primary: '#0076FF',
        deepblue: '#03013D',
        deepblueLight: '#161B51',
        red: '#FF5F33',
        green: '#14A86B',
        lightgreen: '#2CE6C2',
        white: '#FFFFFF',
        lightbluegray: '#8C8FB0',
        pink: '#AC60FC',
        gray: '#F4F5F7',
        deepbluelight: '#1A1D62',
      },
    },
  },
};

export default new Vuetify(opts);
