import store from '@/store'; // eslint-disable-line

import {
  Module,
  VuexModule,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import MainTimeOption from '@/interfaces/mainTimeOption';

@Module({
  namespaced: true,
  store,
})
export default class AppModule extends VuexModule {
  timePossibleOptions: MainTimeOption[] = [
    {
      text: 'Today',
      value: 1,
      normalizedValue: 0,
    },
    {
      text: 'Last 3 days',
      value: 3,
      normalizedValue: 2,
    },
    {
      text: 'Last 7 days',
      value: 6,
      normalizedValue: 5,
    },
    // {
    //   text: 'Last 14 days',
    //   value: 14,
    //   normalizedValue: 13,
    // },
    // {
    //   text: 'Last 30 days',
    //   value: 30,
    //   normalizedValue: 29,
    // },
  ];

  currentTimeOptionValue: MainTimeOption = {
    text: 'Today',
    value: 1,
    normalizedValue: 0,
  };

  @Mutation
  updateAppTime(value) {
    this.currentTimeOptionValue = value;
  }

  @Action({ commit: 'updateAppTime' })
  setAppTime(value) {
    return value;
  }

  get currentTimeOption() {
    return this.currentTimeOptionValue;
  }

  get currentTimeOptions() {
    return this.timePossibleOptions;
  }
}
