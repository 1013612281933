import eventBus from '@/lib/eventBus';
import {
  getEnv,
} from '@/utils/helpers';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

// Vuetify
import '@/plugins/vuetify';
// Service worker
import '@/lib/registerServiceWorker';

function loadPlugins(Vue) {
  const stage = getEnv('ENV') === 'local' ? getEnv('ENV') : getEnv('BUGSNAG_RELEASE_STAGE');
  Bugsnag.start({
    apiKey: '5d6a3297ecf856b847c38f1d2658b5fb',
    appVersion: '2.0.0',
    plugins: [
      new BugsnagPluginVue(),
    ],
    enabledReleaseStages: ['admin-dashboard-dev', 'admin-dashboard'],
    releaseStage: stage,
  });
  Bugsnag.getPlugin('vue')!.installVueErrorHandler(Vue);
}

function loadGlobals(Vue) {
  Object.assign(Vue.prototype, {
    $eventBus: eventBus,
  });
}

export default function initPlugins(Vue) {
  loadPlugins(Vue);
  loadGlobals(Vue);
}
