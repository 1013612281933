import { getView } from '@/utils/helpers';
import { requireAuth } from '@/lib/auth';

export default {
  path: '/bugsnag',
  beforeEnter: requireAuth,
  name: 'Bugsnag',
  component: () => getView('Bugsnag'),
  meta: {
    name: 'Bugsnag',
  },
};
