import { getView } from '@/utils/helpers';
import { requireAuth } from '@/lib/auth';

export default {
  path: '/automation',
  beforeEnter: requireAuth,
  name: 'Automation',
  component: () => getView('Automation'),
  meta: {
    name: 'Automation',
  },
};
