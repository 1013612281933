




























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RefreshSW extends Vue {
  get appLoaded(): boolean {
    return false;
  }

  public isVisible: boolean = false;

  public showRefresh: boolean = false;

  public timeout: number = 2000;

  public text: string = 'Update found';

  public showLoading: boolean = false;

  mounted() {
    this.$eventBus.$on('refreshSW.toggle', (state) => {
      this.showLoading = false;
      if (state.isVisible) this.openModal(state);
      else this.closeModal(state);
    });
  }

  public cancel(): void {
    this.$eventBus.$emit('refreshSW.toggle', { isVisible: false });
  }

  public openModal(state): void {
    Object.assign(this, state);
  }

  public closeModal(state): void {
    Object.assign(this, state);
  }

  public async reload(): Promise<void> {
    // @ts-ignore
    await this.worker.postMessage({ action: 'SKIP_WAITING' });
    window.location.reload(true);
  }
}
