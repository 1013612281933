import { Component } from 'vue';

const getEnv = (key: string): (string|undefined) => process.env[`VUE_APP_${key.toUpperCase()}`];
const randomInteger = (min, max): number => Math.floor((Math.random() * ((max - min) + 1)) + min);
const ucWords = (str: string): string => `${str}`.replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase());
const ucfirst = (str:string): string => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

// Vue related
const getComponent = (name: string): Promise<Component> => import(/* webpackChunkName: "components/[request]" */ `Components/${name}.vue`);
const getView = (name: string): Promise<Component> => import(/* webpackChunkName: "views/[request]" */ `Views/${name}.vue`);

export {
  getEnv,
  randomInteger,
  ucfirst,
  ucWords,
  getComponent,
  getView,
};
