import { getView } from '@/utils/helpers';
import { requireAuth } from '@/lib/auth';

export default {
  path: '/central-logging',
  beforeEnter: requireAuth,
  name: 'CentralLogging',
  component: () => getView('CentralLogging'),
  meta: {
    name: 'Central Logging',
  },
};
