import { getView } from '@/utils/helpers';
import { requireAuth } from '@/lib/auth';

export default {
  path: '/internal-metrics',
  beforeEnter: requireAuth,
  name: 'InternalMetrics',
  // TODO Add real view
  component: () => getView('InternalMetrics'),
  meta: {
    name: 'Internal metrics',
  },
};
