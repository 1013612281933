/* eslint-disable no-console */
import eventBus from '@/lib/eventBus';
import { register } from 'register-service-worker';
import { getEnv } from '@/utils/helpers';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      eventBus.$emit('refreshSW.toggle', {
        isVisible: true,
        text: 'Update found. Downloading update',
        showLoading: true,
      });
      console.log('New content is downloading.');
    },
    async updated(registration) {
      console.log('New content is available; please refresh.');
      const worker = registration.waiting;
      if (getEnv('FORCE_UPDATE')) {
        console.info('Applying system update...');
        await worker.postMessage({ action: 'SKIP_WAITING' });
        setTimeout(() => {
          eventBus.$emit('refreshSW.toggle', {
            isVisible: true,
            text: 'The app was auto updated',
          });
          console.info('Reloading the app...');
          window.location.reload(true);
        }, 2000);
        return;
      }
      eventBus.$emit('refreshSW.toggle', {
        isVisible: true,
        showRefresh: true,
        timeout: 0,
        text: 'New version available! Please update',
        worker,
      });
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
