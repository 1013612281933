
























































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppLoader extends Vue {
  get appLoaded(): boolean {
    return false;
  }

  public isVisible: boolean = false;

  public animationSpeed: string = '2s';

  mounted() {
    this.$eventBus.$on('loading', (state) => {
      this.isVisible = state;
    });
  }
}
