















import { Component, Vue } from 'vue-property-decorator';
import AppLoader from '@/components/common/AppLoader.vue';
import RefreshSW from '@/components/common/RefreshSW.vue';

@Component({
  components: {
    AppLoader,
    RefreshSW,
  },
})
export default class App extends Vue {
}
