import Vue from 'vue';
import VueRouter from 'vue-router';
// import Store from 'Store/index';
// import { SET_USER } from 'Store/mutation-types';
import routes from './routes';

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   /*
//      Override router push method to fix NavigationDuplicated error and
//      uncaught in Promise (related to above) caught by BugSnug
//   */
//   if (onResolve || onReject) {
//     return originalPush.call(this, location, onResolve, onReject);
//   }
//   return originalPush.call(this, location).catch((err) => {
//     if (err && (err || {}).name !== 'NavigationDuplicated') {
//       // If there really is an error, throw it
//       // Should probably be more sophisticated based on the type of err
//       return Promise.reject(err);
//     }
//     // Otherwise resolve to false to indicate the original push call didn't go to its original
//     // destination.
//     return Promise.resolve(false);
//   });
// };

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  // if (!Store.state.account.user.id) {
  //   await Store.commit(SET_USER);
  // }
  // const { user } = Store.state.account;
  // const { groups } = user;
  // if (to.meta.disabled || !(to.meta.allowedFor || []).some((r) => (groups || []).includes(r))) {
  //   if ((!to.meta.allowedForAll && !user.isAdmin) || to.meta.disabled) {
  //     return next({ name: '404' });
  //   }
  // }
  // const cacheSetTime = localStorage.getItem('cacheSetTime');
  // if (!cacheSetTime) {
  //   localStorage.setItem('cacheSetTime', new Date().toString());
  // }
  let title = 'Tasq';
  if (to.meta.title) {
    title = typeof to.meta.title === 'function'
      ? to.meta.title(to) : to.meta.title;
  }
  document.title = title;
  // const cachingTime = new Date().getTime() - new Date(cacheSetTime).getTime();
  // Clear cache if it's older then two days
  // if (cacheSetTime && cachingTime > 48 * 3600 * 1000) {
  //   await localforage.clear();
  //   localStorage.setItem('cacheSetTime', new Date().toString());
  // }
  if (window.navigator.serviceWorker) {
    window.navigator.serviceWorker.getRegistrations()
      .then((registrationsArray) => {
        if (registrationsArray[0]) {
          registrationsArray[0].update();
        }
      });
  }
  return next();
});

export default router;
