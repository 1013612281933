import { getView } from '@/utils/helpers';
import { rejectAuth } from '@/lib/auth';

export default {
  component: () => getView('auth/Login'),
  beforeEnter: rejectAuth,
  path: '/login',
  name: 'Login',
  meta: {
    skipAuth: true,
    bodyClass: ['bg-blue', 'without-footer'],
  },
};
