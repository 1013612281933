import { getView } from '@/utils/helpers';
import { requireAuth } from '@/lib/auth';

export default {
  path: '/',
  beforeEnter: requireAuth,
  name: 'Home',
  component: () => getView('DailySummary'),
  meta: {
    name: 'Daily summary',
  },
};
