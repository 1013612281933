/* eslint-disable no-console */
import jwtDecode from 'jwt-decode';
import jwt from 'jsonwebtoken';
import jwkToPem from 'jwk-to-pem';
import { post } from 'axios';
import { getEnv } from '@/utils/helpers';
// import Store from 'Store/index';
// import { SET_USER } from 'Store/mutation-types';

/**
 * Returns token expiration date from JWT header
 *
 * @link https://jwt.io/
 * @param encodedToken
 * @returns {*}
 */
function decodeToken(token) {
  try {
    return jwtDecode(token);
  } catch (e) {
    localStorage.removeItem('IdToken');
    localStorage.removeItem('RefreshToken');
    window.location = '/login';
    return {};
  }
}

function getTokenExpirationDate(encodedToken) {
  if (!encodedToken) {
    return null;
  }
  const token = decodeToken(encodedToken);

  if (!token.exp) return null;

  return new Date(token.exp * 1000);
}

/**
 * Retrieves JSON Web Key based on Token issuer and key id
 *
 * @param issuer
 * @param keyId
 * @returns {*}
 */
function getJWK(issuer, keyId) {
  return fetch(`${issuer}/.well-known/jwks.json`)
    .then((res) => res.json())
    .then(({ keys }) => keys.find((key) => key.kid === keyId));
}

/**
 * Retrieves a token from a storage
 *
 * @returns {string | null | null}
 */
function getIdToken() {
  return localStorage.getItem('IdToken') || null;
}

/**
 * Stores a token to a storage
 *
 * @param token
 */
function setIdToken(token) {
  localStorage.setItem('IdToken', token);
  // Store.commit(SET_USER);
  return token;
}

/**
 * Returns true if token is expired
 *
 * @param token
 * @returns {boolean}
 */
function isTokenExpired(token = getIdToken()) {
  if (!token) {
    return true;
  }

  const now = new Date();
  const expirationDate = getTokenExpirationDate(token);

  return expirationDate.getTime() <= now.getTime();
}

/**
 * Returns true if token is broken and not conforms to RFC/7519
 *
 * @link https://tools.ietf.org/html/rfc7519
 * @link https://jwt.io/
 * @param token
 * @returns {boolean}
 */
async function isTokenMalformed(token = getIdToken()) {
  if (!token) {
    return true;
  }
  const segments = token.split('.');
  let res = false;
  try {
    const header = JSON.parse(atob(segments[0]));
    const payload = JSON.parse(atob(segments[1]));
    const jwk = await getJWK(payload.iss, header.kid);
    const pem = jwkToPem(jwk);
    jwt.verify(token, pem);
  } catch (e) {
    res = true;
    console.warn('Token is malformed');
  }
  return res;
}

/**
 * Retrieves and stores a new IdToken
 *
 * @returns {PromiseLike<{data: *}> | Promise<{data: *}> | *}
 */
const refreshIdToken = async () => {
  const { email } = decodeToken(getIdToken());
  const refreshToken = localStorage.getItem('RefreshToken');

  try {
    const { data: { tokens: { IdToken } } } = await post(
      `${getEnv('AUTH_ENDPOINT_URL')}/auth`,
      {
        type: 'refresh',
        email,
        refreshToken,
      },
    );
    return setIdToken(IdToken);
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Clears tokens
 */
function clearSession() {
  localStorage.removeItem('IdToken');
  localStorage.removeItem('RefreshToken');
}

/**
 * Clears session and redirects (default) user to the login page
 *
 * @param redirect = true
 */
function logout(redirect = true) {
  clearSession();

  if (redirect) {
    window.location = '/login';
  }
}

/**
 * Returns true, if user's token is not expired
 *
 * @returns {boolean}
 */
function isLoggedIn() {
  const idToken = getIdToken();
  const isExpired = isTokenExpired(idToken);

  return idToken && !isExpired;
}

/**
 * Redirects non-authenticated users to the home page
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
async function requireAuth(to, from, next) {
  if (!isLoggedIn()) {
    try {
      await refreshIdToken();
      next();
    } catch (e) {
      clearSession();
      return next({ name: 'Login' });
    }
  }
  return next();
}

function requireAuthPromise() {
  return new Promise((resolve, reject) => {
    if (isLoggedIn()) {
      return resolve(getIdToken());
    }

    return refreshIdToken()
      .then(resolve)
      .catch(reject);
  });
}

/**
 * Redirects authenticated users to the home page
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
function rejectAuth(to, from, next) {
  if (isLoggedIn()) {
    return next({ path: '/' });
  }

  return next();
}

/**
 * Indicates the current state of access token expiration.
 * If token not yet expired true should be returned
 */
async function isTokenValid() {
  const token = getIdToken();
  const isMalformed = await isTokenMalformed(token);
  const isExpired = isTokenExpired(token);

  return token && !isMalformed && !isExpired;
}

function forceExpireToken() {
  const token = getIdToken();

  if (!token) {
    // eslint-disable-next-line
    console.warn('Token is not exists');
  }

  const tokenSegments = token.split('.');
  // Base64-Decode Payload
  // Convert to JSON
  // Set expiration date to 1970 year
  // Stringify JSON
  // Base64-Encode Payload
  const tokenPayload = btoa(JSON.stringify(Object.assign(JSON.parse(atob(tokenSegments[1])), {
    exp: 0,
  })));

  // Concatenate back to JWT
  // And write to storage
  localStorage.setItem('IdToken', `${tokenSegments[0]}.${tokenPayload}.${tokenSegments[2]}`);
}

function beforeLoginEnter(to, from, next) {
  if (isTokenExpired()) {
    return refreshIdToken().then(() => {
      next({ path: '/' });
    }).catch(() => {
      next();
    });
  }
  return next({ path: '/' });
}

function getUserEmail() {
  const { email } = decodeToken(getIdToken());
  return email;
}

async function getGrpahQLToken() {
  if (isTokenExpired()) {
    await refreshIdToken();
  }
  return getIdToken();
}

export {
  getTokenExpirationDate,
  isTokenExpired,
  isLoggedIn,
  refreshIdToken,
  getIdToken,
  setIdToken,
  rejectAuth,
  requireAuth,
  logout,
  isTokenValid,
  forceExpireToken,
  requireAuthPromise,
  beforeLoginEnter,
  getUserEmail,
  getGrpahQLToken,
  decodeToken,
};
